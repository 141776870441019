@import "modules/responsive-type.scss";
@import url('https://fonts.googleapis.com/css?family=Quicksand:300,400,700');
$primary: #3D4252;
/* MAIN COLOR */

$secondary: #CACBD5;
/* SECONDARY COLOR */

$blk: #000;
/* DARK GRAY, REASON WHY IT'S SET TO #333 IS SO YOU CAN DARKEN IT IF NEEDED USING darken($blk, 10%); FOR EXAMPLE. YOU COULD ALSO USE 2 VARIABLES INSTEAD */

$wht: #fafafa;
$border-radius: 0px;
/* USE THIS TO SET THE BORDER RADIUS FOR BUTTONS */

$footerLinks: #fafafa;
// Set your maximum and minimum screen sizes.
$min_width: 320px;
$max_width: 1920px;
// Font sizes will range between the $min_font and $max_font.
$min_font: 15px;
$max_font: 22px;
// Change these values to change the "scale" between different headers
// (h1,h2,h3, etc.). Larger numbers = larger font-sizes.
$mod_1: 1.2; // mobile
$mod_2: 1.5; // desktop

html,
body {
    height: 100%;
    font-family: 'Quicksand', sans-serif;
}

html {
    @include fluid-type($min_width,
        $max_width,
        $min_font,
        $max_font);
}

p {
    font-size: 1.2em;
    font-weight: lighter
}

h1 {
    font-size: $mod_1*$mod_1*$mod_1*$mod_1 *1rem;
    @include fluid-type($min_width,
        $max_width,
        $mod_1*$mod_1*$mod_1 *$min_font,
        $mod_2*$mod_2*$mod_2 *$min_font);
}

h2 {
    font-size: $mod_1*$mod_1*$mod_1 *1rem;
    @include fluid-type($min_width,
        $max_width,
        $mod_1*$mod_1*$mod_1 *$min_font,
        $mod_2*$mod_2*$mod_2 *$min_font);
}

h3 {
    font-size: $mod_1*$mod_1 *1rem;
    @include fluid-type($min_width,
        $max_width,
        $mod_1*$mod_1 *$min_font,
        $mod_2*$mod_2 *$min_font);
}

body {
    overflow-y: scroll;
    -webkit-overflow-scrolling: touch;
    // background-color: $blk;
}

.flash {
    display: none;
}

q,
blockquote {
    quotes: "“""”";
}

.navbar-default {
    background-color: rgba($blk, .5);

}

.navbarFixed {
    position: fixed;
    width: 100%;
    top: 0;
    z-index: 999;
    background-color: rgba($blk, .5);
    margin-bottom: 0;
    margin-top: 0;

    @media(max-width:991px) {
        background-color: rgba($blk, .8);

    }


}

.special {
    background-color: rgba($blk, .9);
}

/* Change autocomplete styles in WebKit */

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus input:-webkit-autofill,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
    border: 1px solid #0065dd;
    -webkit-text-fill-color: #0065dd;
    -webkit-box-shadow: 0 0 0px 1000px #000 inset;
    transition: background-color 5000s ease-in-out 0s;
}

nav.navbar {
    z-index: 1000;
    border: none;
    border-radius: $border-radius;

    .navbar-nav {
        >li>a {
            text-align: center;
            margin-top: 22px;
            display: flex;
            align-items: center;
            color: $secondary;
            font-size: 1.2em;
            font-weight: italic;

            @media (max-width: 767px) {
                margin-top: 0;
                padding: 8px;
                display: inline-block;
            }

            &:focus,
            &:active {
                background: transparent;
                color: $secondary;
                outline: 0;
            }

            &:hover {
                background: none;
                color: $wht;
                border-bottom: 3px solid $primary;
                font-weight: bold;

                @media (max-width: 1024px) {
                    background: transparent;
                    color: $wht;
                    outline: 0;
                }
            }
        }
    }
}

.navbar-right {
    margin-top: 0px;
}

.navbar-toggle {
    margin: 20px 15px 8px 0px;
    border: 1px solid transparent;
    border-radius: 4px;
}

/** LOGIN FORM **/

@mixin btn {
    background: none;
    color: $wht;
    padding: 1em 2em;
    font-size: 1.2em;
    font-weight: 400;
    text-align: center;
    text-transform: uppercase;
    letter-spacing: 1px;
    display: inline-block;
    margin: 1em 0em;
    transition: 0.7s ease all;
    border: 1px solid $wht;
    border-radius: 5px;

    &:hover {
        background: $wht;
        color: lighten($blk, 10%);
        text-decoration: none;

        @media (max-width: 1024px) {
            background: $secondary;
        }
    }
}

.btn-default {
    @include btn;
}

.modal-dialog {
    max-width: 300px;
    text-align: center;
    margin: 6em auto;

    @media(max-width: 812px)and (orientation:landscape) {
        margin: 0 auto;
    }

    .close {
        display: none;
    }

    .modal-content {
        color: $primary;

        h2 {
            text-align: center;
        }
    }

    input {
        border: 1px solid #777;
        text-align: center;
    }

    input#username,
    input#password {
        @media (max-width: 1024px) {
            font-size: 16px;
        }
    }

    button {
        background: transparent;
        color: $wht;
        display: block;
        border: none;
        padding: 1em 2em;
        margin: 0 auto;
        font-size: 18px;

        &:hover {
            background: transparent;
            color: #fff;
            box-shadow: none;
            text-shadow: 0px 0px 3px $blk;
            border: none;
        }
    }

    input[type="submit"] {
        @include btn;
        display: block;
        background-color: $primary;
        width: 100%;
        font-size: 18px;
        margin: 0 auto;
    }
}

.modal-header,
.modal-footer {
    background: $primary;
    color: $wht;
}

.modal-open {
    overflow: auto;
    padding-right: 0px !important;
}

input#username {
    margin-bottom: 20px;
}

/** END LOGIN FORM **/

//section start
.delay-1 {
    animation-delay: .25s;
}

.delay-2 {
    animation-delay: .5s;
}

.delay-3 {
    animation-delay: .75s;
}

.delay-4 {
    animation-delay: 1s;
}

.box {
    background-color: rgba(0, 0, 0, .7);
    padding: 350px 0;

    @media (max-width: 1024px) {
        padding: 250px 0;
    }

    @media (max-width: 767px) {
        padding: 150px 0;
    }

    @media (max-width: 600px) {
        padding: 100px 0;
    }
}

.bg {
    background-position: top center;
    background-size: cover;
    background-repeat: no-repeat;
}

section {
    @extend .bg
}

.section-a {
    color: $wht;
    background-image: url("../img/bg1.jpg");

    .btn-default {
        margin-right: 10px;
    }
}

.section-b {
    background-image: url("../img/bg2.jpg");
}

.section-c {
    background-image: url("../img/bg3.jpg");
    color: $wht;
}

.section-d {
    background-image: url("../img/bg4.jpg");
    color: $wht;
}

//section end
footer {
    padding: 50px 0px 10px;
    background: $wht;
    color: lighten($blk, 10%);
    font-size: 15px;

    a {
        color: lighten($blk, 10%);
        white-space: nowrap;
        font-size: 15px;

        @media (max-width: 990px) {
            font-size: 13px;
        }

        &:hover {
            color: lighten($blk, 10%);
            outline: 0 !important;
            text-decoration: none;
        }

        &:focus {
            color: $blk;
            outline: 0 !important;
            text-decoration: none;
        }
    }

    p {
        font-size: 15px;

        @media (max-width: 990px) {
            font-size: 13px;
        }
    }
}

input#username,
input#password {
    width: 100%;

    @media (max-width: 1024px) {
        font-size: 16px;
    }
}

select {
    -moz-appearance: none;
    text-indent: 0.01px;
    text-overflow: '';
}

a,
a:hover,
a:focus,
a:active {
    outline: 0 !important;
}

.logo {
    max-height: 100px;
    padding: 0.5em;
}

@media(max-width: 767px) {
    .logo {
        max-height: 70px;
    }

    .navbar-header a {
        float: left;
    }

    .navbar-toggle {
        margin-top: 18px;
    }
}

.top-pad {

    padding: 6em 0em;
    background: white;

    a,
    li,
    p,
    span {
        font-size: .9em;
    }
}

.flex {
    display: flex;
    justify-content: center;
    align-items: center;

    @media (max-width: 1024px) {
        display: block;
        /* may need to be inline-block */
    }
}

.terms,
.privacy {
    font-size: 1rem;

    & ol {
        li {
            font-size: 1rem;
        }

        & ol li {
            font-size: 0.85rem;
        }
    }
}